import { FormikSelect } from '@components/Shared/form-elements/FormikSelect';
import StyledButton from '@components/Shared/StyledButton';
import { putAccount } from '@generated/account';
import { BlubeemRequestsApiContactRequest } from '@generated/brinks.schemas';
import { getContactId, postContact } from '@generated/contact';
import { putOpportunityStep } from '@generated/opportunity';
import { useUser } from '@state/user';
import { useLoader } from '@utilities/context/LoaderContext';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AutoAddress from '../../../Shared/AutoAddress';
import { FormikCheckbox } from '../../../Shared/form-elements/FormikCheckbox';
import { FormikInput } from '../../../Shared/form-elements/FormikInput';
import FormHeader from '../../../Shared/FormHeader';
import FormLayout from '../../../Shared/FormLayout';
import FormLayoutColOne from '../../../Shared/FormLayoutColOne';
import FormLayoutColTwo from '../../../Shared/FormLayoutColTwo';
import { serializeStepTwoFormData } from './converters/form-data.converter';
import { CheckoutStep2FormData } from './models/checkout-step-2-form.model';
import { CHECKOUT_TWO_SCHEMA } from './validation/checkout-two-schema';
import { useTranslation } from 'react-i18next';
import { CountryPrefix, getHostCountryPrefix } from '@utilities/countryPrefix';

const CheckoutTwo = () => {
  const history = useHistory();
  const { toggleLoader } = useLoader();
  const [contact, setContact] = useState<BlubeemRequestsApiContactRequest>();
  const { user, setUser } = useUser();
  const { t, i18n } = useTranslation();
  const countryPrefix = getHostCountryPrefix();

  const getAssociatedContact = async () => {
    toggleLoader(true);
    const { data } = await getContactId(user.account.contactId);
    setContact(data);
    toggleLoader(false);
  };

  useEffect(() => {
    getAssociatedContact();
    putOpportunityStep({ step: '2' });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (formData: CheckoutStep2FormData) => {
    toggleLoader(true);

    const newBillingAddress = formData.useSameInvoiceAddress
      ? user.account.shippingAddress
      : { ...user.account.shippingAddress, ...formData.billingAddress };

    newBillingAddress.country = user.account.shippingAddress.country;

    await putAccount({ billingAddress: newBillingAddress, purchaseOrderNumber: formData.purchaseOrderNumber });
    await postContact({ id: user.account.contactId, ...formData.contact });
    setUser({
      ...user,
      account: {
        ...user.account,
        billingAddress: newBillingAddress,
        purchaseOrderNumber: formData.purchaseOrderNumber,
      },
      step: 3,
    });

    toggleLoader(false);
    history.push('/checkout/step-3');
  };

  return (
    <FormLayout>
      <FormLayoutColOne>
        <FormHeader
          title="CheckoutTwo.header_title"
          indicator={t('step') + ' 2/6'}
          text="CheckoutTwo.header_text"
          hasPrev
          prevUrl="step-1"
        />
      </FormLayoutColOne>
      <FormLayoutColTwo>
        <Formik
          enableReinitialize
          initialValues={serializeStepTwoFormData(user, contact)}
          validationSchema={CHECKOUT_TWO_SCHEMA(t)}
          onSubmit={onSubmit}
        >
          {({ values, setErrors }) => {
            i18n.on('languageChanged', () => {
              setErrors({});
            });
            return (
              <Form>
                <FormikInput label="ContactInformation.email" name="contact.email" type="email" formType="tertiary" />
                <FormikInput label="ContactInformation.first_name" name="contact.firstname" formType="tertiary" />
                {countryPrefix !== CountryPrefix.Belgium && (
                  <FormikInput label="ContactInformation.preposition" name="contact.middlename" formType="tertiary" />
                )}
                <FormikInput label="ContactInformation.surname" name="contact.lastname" formType="tertiary" />
                <FormikSelect
                  label="ContactInformation.salutation"
                  hideLabel
                  name="contact.gender"
                  placeholder="ContactInformation.select_salutation"
                  options={[
                    { value: 'M', displayValue: t('ContactInformation.sir') },
                    { value: 'F', displayValue: t('ContactInformation.madam') },
                  ]}
                />
                <FormikInput
                  label="CheckoutTwo.purchase_order_number"
                  name="purchaseOrderNumber"
                  formType="tertiary"
                  tooltip="CheckoutTwo.purchase_order_number_tooltip"
                />
                <FormikCheckbox
                  label="CheckoutTwo.billing_address"
                  name="useSameInvoiceAddress"
                  formType="tertiary"
                  align="center"
                />
                <div className={values.useSameInvoiceAddress ? 'u-transition-height--hidden' : ''}>
                <div className="u-transition-height u-flex u-flex-wrap u-flex-between">
                <div className="form__item--half">
                  <FormikInput label="Address.postal_code" name="billingAddress.zipCode" formType="tertiary" />
                </div>
                <div className="form__item--half">
                  <FormikInput label="Address.city" name="billingAddress.city" formType="tertiary" />
                </div>
                </div>
                <div className="form__item--full">
                  <FormikInput label="Address.street" name="billingAddress.street" formType="tertiary" />
                </div>
                </div>
                <StyledButton tag="button" text="Buttons.next" type="submit" formType="tertiary" />
              </Form>
            );
          }}
        </Formik>
      </FormLayoutColTwo>
    </FormLayout>
  );
};

export default CheckoutTwo;
